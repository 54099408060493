.header {
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  }